/*Inter Regular Font*/
@font-face {
  font-family: 'Inter';
  font-display: auto;
  font-weight: 400;
  font-style: normal;
  src: local('Inter Regular'), local('Inter-Regular'),
  url('../fonts/Inter-Regular.woff2') format('woff2'),
  url('../fonts/Inter-Regular.woff') format('woff'),
  url('../fonts/Inter-Regular.ttf') format('truetype');
}
/*Inter Medium Font*/
@font-face {
  font-family: 'Inter';
  font-display: auto;
  font-weight: 500;
  font-style: normal;
  src: local('Inter Medium'), local('Inter-Medium'),
  url('../fonts/Inter-Medium.woff2') format('woff2'),
  url('../fonts/Inter-Medium.woff') format('woff'),
  url('../fonts/Inter-Medium.ttf') format('truetype');
}
/*Inter Bold Font*/
@font-face {
  font-family: 'Inter';
  font-display: auto;
  font-weight: bold;
  font-style: normal;
  src: local('Inter Bold'), local('Inter-Bold'),
  url('../fonts/Inter-Bold.woff2') format('woff2'),
  url('../fonts/Inter-Bold.woff') format('woff'),
  url('../fonts/Inter-Bold.ttf') format('truetype');
}