footer {
  font-family: $bs-font-family;
  font-size: $font-size;

  .lh-b5 {
    line-height: 149% !important;
    @include media-breakpoint-up(md) {
      line-height: 150% !important;
    }
  }

  .rb-link {
    line-height: 100% !important;
  }

  h5 {
    font-family: $bs-font-family;
    font-size: $font-size;
    color: $body-color;
  }

  .container-public-menu {
    padding-left: 19.2px;
    padding-right: 19.2px;
    @include media-breakpoint-up(md) {
      padding-left: 12px;
      padding-right: 12px;
    }

    .row {
      margin-left: -0.75rem !important;
      margin-right: -0.75rem !important;
    }
  }

  .footer-link {
    text-decoration: none;
    color: $grey-900 !important;
    font-size: $font-size-small !important;

    a {
      color: $grey-900;
    }

    &:hover,
    &:active {
      color: $grey-700 !important;
    }

    @include media-breakpoint-up(sm) {
      font-size: $font-size !important;
    }
  }

  .list-unstyled-pwa {
    padding-left: 0 !important;
    list-style: none !important;
  }

  .list-unstyled {
    a {
      color: $body-color;

      &:hover,
      &:active {
        color: darken($body-color, 15%);
      }

      @include media-breakpoint-down(sm) {
        font-size: $font-size-small;
      }
    }

    &.social-buttons {
      justify-content: center;
    }
  }

  &.app-footer {
    flex: 0 0 51px;
    height: 51px;
  }
}