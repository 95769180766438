.pagination {
    .page-item {
        @include media-breakpoint-down(md) {
            &:not(.active):not(.arrow) {
                display: none;
            }
        }

        &:not(.arrow) {
            min-width: calc-rem(40px);
            margin: 0 calc-rem(1px);

            &.active {
                .page-link {
                    font-weight: bold;
                }
            }
        }
    }

    a {
        color: $grey;
        border-color: #dee2e6;
        text-align: center
    }

    .arrow {
        margin: 0 calc-rem(30px);

        .arrow-left {
            background: url("../img/icons/left-arrow.png") no-repeat;
            width: calc-rem(30px);
            height: calc-rem(18px);
            display: inline-block;
            margin-right: 0 !important;
            cursor: pointer;

            &:hover {
                background: url("../img/icons/right-arrow-active.png") no-repeat;
                transform: rotate(180deg);
            }
        }

        .arrow-right {
            background: url("../img/icons/left-arrow.png") no-repeat;
            transform: rotate(180deg);
            width: calc-rem(30px);
            height: calc-rem(18px);
            display: inline-block;
            cursor: pointer;
            margin-left: 0 !important;

            &:hover {
                background: url("../img/icons/right-arrow-active.png") no-repeat;
                transform: rotate(0);
            }
        }

        @include media-breakpoint-down(sm) {
            margin: 0 calc-rem(10px);
        }
    }
}