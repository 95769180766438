.sidebar {
  background-color: $white;
  font-family: $bs-font-family;
  font-size: $font-size-small;

  .sidebar-nav {
    background-color: $white;
  }

  .nav-dropdown.open {
    background-color: $white;

    .nav-link {
      color: $body-color;
    }
  }

  .nav-item {

    .nav-link {
      color: $body-color;

      .icon-shop {
        mask: url("../img/bg/ico_shop.svg") no-repeat center;
        background: $body-color;
        height: 28px;
        width: 28px;
      }

      .icon-home {
        mask: url("../img/bg/ico_home.svg") no-repeat center;
        background: $body-color;
        height: 28px;
        width: 28px;
      }

      &:hover,
      &:active,
      &.active {
        color: $white;
        background-color: $active-sidebar-color;

        .icon-shop {
          background: $white;
        }

        .icon-home {
          background: $white;
        }
      }
    }

    > .nav-link {
      padding-left: 0.5rem !important;
    }

    .nav-dropdown-items {
      a.nav-link {
        padding-left: 1.875rem !important;

        .badge {
          padding: .25em;
          margin-top: .25em;
        }
      }
    }
  }

  .sidebar-toggler {
    display: flex;
    background-color: $white;
    flex: 0 0 50px;
    justify-content: flex-end;
    cursor: pointer;
    border: 0;
    padding: 0;

    &::before {
      display: block;
      width: 50px;
      height: 50px;
      content: "";
      background-repeat: no-repeat;
      background-position: center;
      background-size: 12.5px;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%2373818f' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
    }

    &:hover {
      background-color: $primary;

      &::before {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
      }
    }
  }
}

.sidebar-show,
.sidebar-md-show {
  .main {
    position: relative;
    margin-left: 0;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1018;
      width: 100%;
      height: 100%;
      content: "";
      background: rgba(33, 37, 41, .5);
      animation: opacity .5s;
    }
  }
}