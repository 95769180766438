$color-light: #ffffff;
$breakpoint: 992px;

.nav-listing-types {
  background: $btn-group-grey;
  margin-bottom: map_get($spacers, 3);
  color: $color-text-black;
  font-size: calc-rem(18px);

  .rebates-active{
    &.active {
      background: $bg-orange-block;
      border-radius: calc-rem(54px);
      color: $color-light;
    }
  }
  .coupons-active{
    &.active {
      background: $bg-green-block;
      border-radius: calc-rem(54px);
      color: $color-light;
    }
  }
  .deals-active{
    &.active {
      background: $bg-grey-block;
      border-radius: calc-rem(54px);
      color: $color-light;
    }
  }
  .btn-listing-type {
    min-width: 3rem;
    transition: all .5s;

    &:focus {
      outline: 0;
      box-shadow: 0 0;
    }


    @include media-breakpoint-down(xs) {
      min-width: 6rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }
}


.menu-link {
  &[aria-haspopup="true"] {
    ~ .mega-menu {
      display: none;
    }
  }
}

// ------------------ MEDIA QUERIES

@media all and (min-width: $breakpoint) {
  .menu-bar {
    position: relative;
    display: flex;
    background: $color-light;

    li {
      list-style: none;
    }
  }

  .mega-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: $color-light;
    overflow: hidden;
    padding: 1.5rem 0;
    border-top: 1px solid $gray-200;
    border-bottom: 1px solid $gray-200;

    .nav-pills {
      .nav-link {
        padding-bottom: .5rem !important;
        padding-top: .5rem !important;

        &.active {
          background-color: $dark !important;
          color: $white !important;
        }
      }
    }

    .tab-pane {
      padding-left: 0;
      padding-right: 0;

    }
  }

  .mobile-menu-trigger, .mobile-menu-trigger, .mobile-menu-back-item {
    display: none;
  }
  .menu-bar {
    > li.openMenu {
      .mega-menu {
        display: block;
        transform-origin: top;
        animation: dropdown .2s ease-out;
      }

      > a {
        position: relative;

        &::after {
          content: ' ';
          display: block;
          background: $color-light;
          border-top: 1px solid $gray-200;
          border-left: 1px solid $gray-200;
          height: 16px;
          width: 16px;
          position: absolute;
          right: 0;
          left: 0;
          margin: 0 auto;
          top: 33px;
          transform: rotate(45deg);
          animation: arrow-show .2s ease-out;
          z-index: 1;
        }
      }
    }
  }
}

// ------------------ ANIMATIONS

@keyframes dropdown {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes arrow-show {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}