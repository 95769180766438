section {
  &.bg-landing {
    background: url("../img/bg/landing-main-bg.jpg") no-repeat center;
    background-size: cover;
  }
}

.map-bg {
  background: url("../img/bg/map1.webp") center no-repeat;
  background-size: 540px;
  @include media-breakpoint-up(xl) {
    background: url("../img/bg/map1.webp") 48% 0 no-repeat;
    background-size: 730px;
  }

}

.about-us-bg {
  background: url("../img/bg/about-us-bg.jpg") no-repeat center;
  background-size: cover;
}

.bg-blocks {
  background: $bg-blocks-blue;
}

.buyer-sign-up-form {
  &:after {
    content: "";
    background: url('../img/bg/main-bg-mob2.5.webp') no-repeat center;
    background-size: 100%;
    width: 80vw;
    height: 55vw;
    position: absolute;
    left: 5rem;
    top: 0;
    transform: translateY(-102%);
    z-index: -2;
  }

  &:before {
    content: "";
    background: linear-gradient(180deg, #1AA5BD 0%, #188CAE 100%);
    height: calc(100% + 30px);
    width: 100vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: -1;
  }

  @include media-breakpoint-up(sm) {
    &:after {
      content: "";
      background: url('../img/bg/main-bg-mob2.5.webp') no-repeat center;
      background-size: 100%;
      width: 50vw;
      height: 28vw;
      position: absolute;
      left: 20rem;
      top: 0;
      transform: translateY(-105%);
      z-index: -2;
    }
  }
}

.main-bg {

  @include media-breakpoint-up(md) {
    background: url('../img/bg/main-bg-2.5.webp') no-repeat 68% top;
    background-size: cover;
    #collapseForm {
      display: block;
    }
  }

  @include media-breakpoint-up(lg) {
    background: url('../img/bg/main-bg-2.5.webp') no-repeat 65% top;
    background-size: cover;
  }

  @include media-breakpoint-up(uxxl) {
    background: url('../img/bg/main-bg-2.5.webp') no-repeat 66% 25%;
    background-size: cover;
  }
}

.testimonial-bg {
  background: url("../img/bg/testimonials-bg.webp") no-repeat center;
  background-size: cover;
}

.gradient-bg {
  background: #f9fbfe; /* Old browsers */
  background: -moz-linear-gradient(top, #f9fbfe 0%, #ffffff 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #f9fbfe 0%, #ffffff 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #f9fbfe 0%, #ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9fbfe', endColorstr='#ffffff', GradientType=0); /* IE6-9 */
}

.message-bg {
  background: url("../img/bg/messages-bg.png") no-repeat bottom center;
  background-size: 100% auto;
}

.sign-up-bg {
  background: url("../img/bg/sign-up-bg.jpg") no-repeat calc-rem(100px) 100%;
  background-size: cover;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  padding-left: 120px;

  @include media-breakpoint-up(xl) {
    z-index: 1021;
  }
}

.affiliate-bg {
  background: url("../img/bg/affiliate-bg.jpg") no-repeat center;
  background-size: cover;
}

.pc-bg {
  background: url("../img/bg/pc-bg.png") no-repeat right;
  background-size: auto;
}

.dollar-bg {
  background: url("../img/bg/dollar-bg.png") no-repeat center;
  background-size: 100% auto;
}


@media (max-width: 1700px) {
  .pc-bg {
    background: url("../img/bg/pc-bg.png") no-repeat right;
    background-size: 45%;
  }
}


@media (max-width: 1500px) {
  .sign-up-bg {
    background-position: 2.25rem 100%;
    padding-left: 2.5rem;
  }
}

@include media-breakpoint-up(xl) {
  .message-bg {
    background-size: auto;
  }
}

@include media-breakpoint-down(lg) {
  .sign-up-bg {
    background-position: 0 100%;
    position: relative;
    height: auto;
    width: 100%;
    border-radius: $border-radius;
    display: flex;
    padding-left: 0;
    margin-bottom: calc-rem(30px);
  }
}

@include media-breakpoint-down(md) {
  .pc-bg {
    background: none;
  }
}

@include media-breakpoint-down(sm) {
  .message-bg, .dollar-bg {
    background: none;
  }
}